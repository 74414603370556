import React, { FC, useState } from 'react'
import { useSnackbar } from 'presentation/components/Snackbar/useSnackbar'
import { postSetSecretQAUpdate } from 'api/postSetSecretQA'
import { navigate } from 'gatsby'
import { useTranslation } from 'react-i18next'
import { LOWWER_LENGTH, UPPER_LENGTH } from 'scripts/secretQAValidate'

interface Props {
  token: string
}

export const Main: FC<Props> = ({ token }) => {
  const [question, setQuestion] = useState('')
  const [answer, setAnswer] = useState('')
  const [openSnackbar] = useSnackbar()
  const { t } = useTranslation()

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    switch (event.target.name) {
      case 'question':
        setQuestion(event.target.value)
        break
      case 'answer':
        setAnswer(event.target.value)
        break
      default:
        break
    }
  }

  const handleClick = async () => {
    const VALIDATE_BLANK = !question || !answer
    if (VALIDATE_BLANK) {
      openSnackbar('秘密の質問が空です', 'inputBlankSnackbar')
      return
    }
    const VALIDATE_UPPER =
      UPPER_LENGTH < question.length || UPPER_LENGTH < answer.length
    if (VALIDATE_UPPER) {
      openSnackbar(
        '文字数が設定可能範囲を超えています',
        'inputOverLimitSnackbar',
      )
      return
    }
    const VALIDATE_LOWER =
      question.length < LOWWER_LENGTH || answer.length < LOWWER_LENGTH
    if (VALIDATE_LOWER) {
      openSnackbar(
        '文字数が設定可能範囲を下回っています',
        'inputLowwerLimitSnackbar',
      )
      return
    }
    const params = {
      token,
      question,
      answer,
    }
    const { status } = await postSetSecretQAUpdate(params)
    if (status === 200) {
      navigate('/setSecretQuestionSuccess')
    } else {
      openSnackbar('秘密の質問の設定に失敗しました。')
    }
  }

  return (
    <>
      <p className="text-xl">{t('秘密の質問')}</p>
      <div className="w-full px-6">
        <p className="text-xs font-semibold text-gray-700 pb-1">
          {t('秘密の質問')}
        </p>
        <input
          className="rounded px-2 w-full h-8 border border-black"
          data-test="question"
          type="text"
          name="question"
          value={question}
          onChange={handleChange}
          placeholder="8~32文字"
        />
      </div>
      <div className="w-full px-6 pt-4">
        <p className="text-xs font-semibold text-gray-700 pb-1">
          {t('秘密の質問の答え')}
        </p>
        <input
          className="rounded px-2 w-full h-8 border border-black"
          data-test="answer"
          type="text"
          name="answer"
          value={answer}
          onChange={handleChange}
          placeholder="8~32文字"
        />
      </div>
      <div className="w-full px-6 mt-8">
        <button
          className="h-12 font-semibold w-full rounded bg-locked text-white hover:opacity-75"
          data-test="submit"
          onClick={handleClick}
        >
          {t('設定する')}
        </button>
      </div>
      <div className="mt-2 mb-8">
        <a
          className="locked-help-link"
          href="https://www.notion.so/onetapincjp/3d11301ecb5e4496b71389e57c88396f"
        >
          {t('秘密の質問に関するヘルプ')}
        </a>
      </div>
    </>
  )
}
