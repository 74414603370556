import { GetCheckSecretQuestionSetTokenResponse } from 'types/ApiResponse'
import { useAxios } from 'scripts/api'
import { fullUrl } from 'scripts/fullUrl'

export const useCheckSecretQuestionSetToken = (
  token: string | null | undefined,
) => {
  const [
    { data, error, loading },
  ] = useAxios<GetCheckSecretQuestionSetTokenResponse>(
    `${fullUrl()}/set_secret_question?token=${token}`,
  )
  return [{ ...data, error, loading }]
}
