import { fullUrl } from 'scripts/fullUrl'
import {
  PostPasswordResetConfirmResponse,
  PostSecretQAUpdateResponse,
} from 'types/ApiResponse'
import {
  PostPasswordResetConfirmRequest,
  PostSecretQAUpdateRequest,
} from 'types/ApiRequest'
import { axiosClient } from 'scripts/api'

export const postSetSecretQAUpdate = async (
  params: PostSecretQAUpdateRequest,
) => {
  const res = await axiosClient.post<PostSecretQAUpdateResponse>(
    `${fullUrl()}/set_secret_question`,
    params,
  )
  return res
}
