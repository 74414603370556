import React from 'react'
import { AuthenticationLayout as Layout } from 'presentation/components/AuthenticationLayout'
import { Main } from './main'
import { StringParam, useQueryParam } from 'use-query-params'
import { useCheckSecretQuestionSetToken } from 'api/useCheckSecretQuestionSetToken'
import { Loading } from 'presentation/components/Loading'

export const SetSecretQuestion = () => {
  const [token] = useQueryParam('token', StringParam)
  const [{ status, loading }] = useCheckSecretQuestionSetToken(token)

  if (loading) {
    return (
      <main className="pb-20 pt-10 w-full flex justify-center">
        <Loading />
      </main>
    )
  }

  if (status === 200 && token) {
    return (
      <Layout dataTest="availableSecretQuestionTokenLayout">
        <Main token={token}></Main>
      </Layout>
    )
  }

  return (
    <main className="pb-20 pt-10 w-full flex justify-center">
      <Loading />
    </main>
  )
}
